import { queryStringToObject, getCookies, setCookie, deleteCookie } from '.';

/**
 * @description List of UTM keys that we use in kotas
 */
export const UTM = Object.freeze({
  REF: 'ref',
  CUPOM: 'cupom',
  TERM: 'utm_term',
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CONTENT: 'utm_content',
  CAMPAIGN: 'utm_campaign',
  COD_INDICACAO: 'cod_indicacao',
});

/**
 * @description List of domains that we use in kotas
 * Localhost: development
 * Kotas: production
 * Ngrok: staging
 */
const DOMAINS = Object.freeze({
  LOCALHOST: 'localhost',
  KOTAS: '.kotas.com.br',
  NGROK: '.sa.nkrok.com.br',
});

/**
 * @description Returns a date 5 years from now
 * @returns {Date}
 */
export function getExpirationTime() {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate());
}

/**
 * @description Returns the domain to be used in the cookie
 * @returns {string}
 */
export function resolveDomain() {
  let domain = '';

  for (const key in DOMAINS) {
    if (window.location.hostname.includes(key.toLowerCase())) {
      domain = DOMAINS[key];
      break;
    }
  }

  return domain;
}

/**
 * @description Sets the UTM cookie
 * @param {object} utms
 * @returns {boolean}
 */
export function setUTMCookie(utms) {
  if (!utms || !Object.keys(utms).length) return false;
  /**
   * @description Deletes all UTM cookies before setting a new one
   */
  deleteAllUTMCookies();

  const domain = resolveDomain();
  const expires = getExpirationTime();

  Object.entries(utms).forEach(([name, value]) => {
    setCookie({ value, domain, name, expires, path: '/' });
  });

  return true;
}

/**
 * @description Returns the UTM query if it exists or deletes all UTM cookies otherwise
 * @returns {object}
 */
export function getUTMQuery() {
  const query = queryStringToObject();
  return query ? filter(query) : deleteAllUTMCookies();
}

/**
 * @description Deletes all UTM cookies
 * @returns {void}
 */
export function deleteAllUTMCookies() {
  Object.values(UTM).forEach((name) => {
    deleteCookie(name, resolveDomain());
  });
}

/**
 * @description Returns the UTM cookie if it exists or null otherwise
 * @returns {object}
 */
export function getUTMCookie() {
  const cookies = getCookies();
  return cookies ? filter(cookies) : null;
}

/**
 * @description filters the object to only include UTM keys
 * @param {object} entry
 * @returns {object}
 */
function filter(entry) {
  const { entries, fromEntries, values } = Object;
  return fromEntries(entries(entry).filter(([key]) => values(UTM).includes(key)));
}
