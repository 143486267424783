<template>
  <div class="landing">
    <NavBar target="home" />

    <section class="content reverse wrapper">
      <div>
        <h1 class="landing-title">Divida assinaturas e economize até 80% em</h1>
        <textra :data="words" :timer="3" :infinite="true" filter="simple" class="textra" />
        <p>
          Com o Kotas você pode compartilhar mais de 500 serviços e assinaturas de forma fácil,
          rápida e segura.
        </p>

        <a
          v-gtm.click="{
            category: 'home',
            action: 'click',
            label: '1-cta-cadastrar-gratuitamente',
          }"
          href="https://app.kotas.com.br/cadastro"
        >
          <button class="btn-primary">Cadastrar gratuitamente</button>
        </a>
      </div>
      <img
        class="img-decoration allpersons"
        width="784"
        height="357"
        src="../assets/landing-page/all_persons-large.webp"
        srcset="
          ../assets/landing-page/all_persons-small.webp 408w,
          ../assets/landing-page/all_persons-large.webp 784w
        "
        sizes="(max-width: 480px) 408w, 784w"
        alt
      />
    </section>

    <section class="content">
      <div class="centered">
        <h2 class="h2">Estamos na mídia</h2>
        <h3 class="h3">Somos destaques nos maiores portais de conteúdo do Brasil</h3>

        <carousel
          :perPageCustom="[
            [768, 2],
            [1024, 6],
          ]"
          :autoplay="true"
          :paginationEnabled="false"
          :loop="true"
          :autoplayTimeout="5000"
          :scrollPerPage="false"
        >
          <slide>
            <a
              href="https://canaltech-com-br.cdn.ampproject.org/c/s/canaltech.com.br/amp/internet/como-usar-kotas-dividir-netflix-spotify/"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/canaltech.webp"
                alt="canaltech"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="https://catracalivre.com.br/geral/dica-digital/indicacao/quer-rachar-netflix-ou-o-spotify-com-alguem-conheca-o-kotas/"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/cartracalivre.webp"
                alt="cartraca livre"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="http://economia.estadao.com.br/noticias/suas-contas,site-permite-que-usuarios-rachem-a-conta-do-netflix-e-spotify,74001735565"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/estadao.webp"
                alt="estadao"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="https://exame.com/seu-dinheiro/site-permite-rachar-assinaturas-de-netflix-e-spotify/"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/exame.webp"
                alt="exame"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="https://www.infomoney.com.br/consumo/site-permite-rachar-conta-na-netflix-spotify-e-ate-conteudo-adulto/"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/infomoney.webp"
                alt="infomoney"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="https://simi.mg.gov.br/startup-mineira-ajuda-voce-a-dividir-assinaturas-de-netflix-e-spotify/"
              target="_blank"
              rel="noopener"
              alt="Startup mineira ajuda voce a dividir assinaturas de netflix e spotify"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/simi.webp"
                alt="simi"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="http://www.almanaquesos.com/site-reune-pessoas-que-querem-compartilhar-um-servico-dividir-conta-netflix/"
              target="Blanck"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/sos.webp"
                alt="sos"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="https://www.tecmundo.com.br/netflix/115796-servico-busca-pessoas-dividir-contas-spotify-netflix.htm"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/tecmundo.webp"
                alt="tecmundo"
                rel="noopener"
              />
            </a>
          </slide>
          <slide>
            <a
              href="https://olhardigital.com.br/2021/03/26/tira-duvidas/saiba-como-dividir-os-custos-de-streamings-com-os-amigos/"
              target="_blank"
              rel="noopener"
            >
              <img
                class="img-fluid"
                src="../assets/landing-page/olharDigital.webp"
                alt="olhar digital"
              />
            </a>
          </slide>
        </carousel>
      </div>
    </section>

    <section class="grey-section">
      <div class="content">
        <UndrawPizza class="img-decoration undrawpizza" />
        <div>
          <carousel :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="5000">
            <slide>
              <p>Compartilhe suas assinaturas</p>
              <div class="carousel">
                <h2 class="h2">
                  Crie um grupo
                  <br />e compartilhe
                </h2>
                <h3 class="h3">
                  É bem simples. Você informa qual serviço, o valor e quantas vagas tem disponível.
                  Pronto, seu grupo logo estará disponível para receber membros
                </h3>
              </div>
            </slide>
            <slide>
              <p>Compartilhe suas assinaturas</p>
              <div class="carousel">
                <h2 class="h2">
                  Divulgue para
                  <br />sua galera
                </h2>
                <h3 class="h3">
                  Compartilhe seu grupo com amigos e familiares. Quando todas as vagas estiverem
                  preenchidas é só ativar
                </h3>
              </div>
            </slide>
            <slide>
              <p>Compartilhe suas assinaturas</p>
              <div class="carousel">
                <h2 class="h2">
                  Receba a parte de
                  <br />cada integrante
                </h2>
                <h3 class="h3">
                  Após ativar o grupo é só aguardar que mensalmente mandaremos o dinheiro dos
                  participantes para sua conta
                </h3>
              </div>
            </slide>
          </carousel>

          <a
            v-gtm.click="{
              category: 'home',
              action: 'click',
              label: '2-cta-criar-meu-grupo',
            }"
            href="https://app.kotas.com.br/cadastro"
          >
            <button class="btn-secondary">Criar meu grupo</button>
          </a>
        </div>
      </div>
    </section>

    <section class="content reverse">
      <div>
        <carousel :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="5000">
          <slide>
            <p>Encontre grupos para fazer parte</p>
            <div class="carousel">
              <h2 class="h2">
                Pesquise por grupos
                <br />de seu interesse
              </h2>
              <h3 class="h3">
                Pesquise por grupos abertos e com vagas disponíveis. Escolha o que melhor identifica
                com você
              </h3>
            </div>
          </slide>
          <slide>
            <p>Encontre grupos para fazer parte</p>
            <div class="carousel">
              <h2 class="h2">
                Faça sua inscrição
                <br />para participar
              </h2>
              <h3 class="h3">
                Após escolher um grupo é só realizar a inscrição em poucos passos. Você pode pagar
                em Boleto ou em Cartão de Crédito
              </h3>
            </div>
          </slide>
          <slide>
            <p>Encontre grupos para fazer parte</p>
            <div class="carousel">
              <h2 class="h2">
                Divirta-se com o que
                <br />mais importa
              </h2>
              <h3 class="h3">
                O administrador irá enviar os dados necessários para o acesso. Então é só curtir o
                serviço e a economia
              </h3>
            </div>
          </slide>
        </carousel>

        <a
          v-gtm.click="{
            category: 'home',
            action: 'click',
            label: '3-cta-econtrar-um-grupo',
          }"
          href="https://app.kotas.com.br/cadastro"
        >
          <button class="btn-secondary">Encontrar um grupo</button>
        </a>
      </div>

      <img
        class="img-decoration undrawmissedchances"
        width="475"
        height="384"
        src="../assets/landing-page/undraw_missed_chances.webp"
        alt
      />
    </section>

    <section class="grey-section">
      <div class="content">
        <div class="cards-box">
          <div class="assinatura">
            <div class="top1"></div>
            <div class="card-img">
              <div class="card-body">
                <div class="icone">
                  <Musica />
                </div>
                <h2 class="card-title">Música</h2>
                <p class="card-text">
                  <Union />
                  <b>2</b> vagas disponíveis
                </p>
                <p class="card-text">
                  <Coin />
                  <b>8,90</b> por mês
                </p>
                <div class="user">
                  <div class="foto1"></div>
                  <div>
                    <h2>Lucas</h2>
                    <p>administrador</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="assinatura">
            <div class="top2"></div>
            <div class="card-img">
              <div class="card-body">
                <div class="icone">
                  <Curso />
                </div>
                <h2 class="card-title">Cursos</h2>
                <p class="card-text">
                  <Union />
                  <b>3</b> vagas disponíveis
                </p>
                <p class="card-text">
                  <Coin />
                  <b>25,00</b> por mês
                </p>
                <div class="user">
                  <div class="foto2"></div>
                  <div>
                    <h2>Beatriz</h2>
                    <p>administrador</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="assinatura">
            <div class="top3"></div>
            <div class="card-img">
              <div class="card-body">
                <div class="icone">
                  <Cinema />
                </div>
                <h2 class="card-title">Séries e Filmes</h2>
                <p class="card-text">
                  <Union />
                  <b>1</b> vagas disponíveis
                </p>
                <p class="card-text">
                  <Coin />
                  <b>16,40</b> por mês
                </p>
                <div class="user">
                  <div class="foto3"></div>
                  <div>
                    <h2>Roberto</h2>
                    <p>administrador</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="assinatura">
            <div class="top4"></div>
            <div class="card-img">
              <div class="card-body">
                <div class="icone">
                  <Game />
                </div>
                <h2 class="card-title">Games</h2>
                <p class="card-text">
                  <Union />

                  <b>2</b> vagas disponíveis
                </p>
                <p class="card-text">
                  <Coin />
                  <b>19,90</b> por mês
                </p>
                <div class="user">
                  <div class="foto4"></div>
                  <div>
                    <h2>Michael</h2>
                    <p>administrador</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Seu bolso agradece</p>
          <h2 class="h2">Muito mais assinaturas para você</h2>
          <p>aproveite para ter uma gama de serviços que sempre quis por um preço mais acessível</p>

          <a
            v-gtm.click="{
              category: 'home',
              action: 'click',
              label: '4-cta-ver-grupos',
            }"
            href="https://app.kotas.com.br/cadastro"
          >
            <button class="btn-primary">Ver grupos</button>
          </a>
        </div>
      </div>
    </section>

    <section class="content reverse">
      <div>
        <carousel :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="5000">
          <slide>
            <p>Mas compartilhar um serviço é legal?</p>
            <div class="carousel">
              <h2 class="h2">
                Sim! Planos família ou multiusuários
                <br />são feitos para isso
              </h2>
              <p>
                Esses planos já preveem o uso de mais de uma pessoa informando o nº de usuários ou
                dispositivos simultâneos. Cada termo de uso pode ter alguma especificidade que deve
                ser respeitada. O Kotas é apenas a ferramenta financeira do processo.
              </p>
            </div>
          </slide>
          <slide>
            <p>Mas compartilhar um serviço é legal?</p>
            <div class="carousel">
              <h2 class="h2">
                "Mas eu já estou compartilhando
                <br />uma assinatura"
              </h2>
              <p>
                Muitas vezes pagamos sozinhos por um serviço que compartilhamos com mais pessoas.
                Mas se você acha que é justo todos pagarem sua parte e deseja começar a compartilhar
                a mensalidade sem a necessidade de lembrar que paguem todos os meses... Você veio ao
                lugar certo!
              </p>
            </div>
          </slide>
        </carousel>

        <a
          v-gtm.click="{
            category: 'home',
            action: 'click',
            label: '5-cta-cadastre-se-gratis',
          }"
          href="https://app.kotas.com.br/cadastro"
        >
          <button class="btn-primary">Cadastre-se grátis</button>
        </a>
      </div>

      <img
        class="img-decoration undrawjudge"
        width="360"
        height="325"
        src="../assets/landing-page/undraw_judge-large.webp"
        alt
      />
    </section>

    <section class="grey-section">
      <div class="content tw-section">
        <img
          class="img-decoration midias"
          :src="require('@/assets/landing-page/midias-small.webp')"
        />

        <div class="x-carousel">
          <p>O que nossos usuários estão falando</p>
          <h2 class="h2">
            Opiniões reais de pessoas como você,
            <br />sobre nós
          </h2>
          <carousel :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="5000">
            <slide>
              <a
                href="https://x.com/_beabps/status/1123635581043597312"
                rel="noreferrer"
                target="_blank"
              >
                <div class="tweet">
                  <h2>bea obrien</h2>
                  <h3>@beabps_</h3>
                  <p>
                    uma das coisas boas que encontrei na internet esse ano foi o @kotasoficial que
                    serve pra você rachar assinaturas com outras pessoas
                  </p>

                  <img src="../assets/landing-page/tw_logo.png" class="tw" alt="Ícone X" />
                </div>
              </a>
            </slide>

            <slide>
              <a
                href="https://x.com/pluftz/status/1084081928641359874"
                rel="noreferrer"
                target="_blank"
              >
                <div class="tweet">
                  <h2>jeff 💭</h2>
                  <h3>@pluftz</h3>
                  <p>
                    A melhor coisa que fiz esse ano (so far) foi entrar num grupo do Kotas pra
                    dividir o YouTube Premium
                  </p>

                  <img src="../assets/landing-page/tw_logo.png" class="tw" alt="Ícone X" />
                </div>
              </a>
            </slide>

            <slide>
              <a
                href="https://x.com/dlassad/status/1090293984222765056"
                rel="noreferrer"
                target="_blank"
              >
                <div class="tweet">
                  <h2>Daniel Assad</h2>
                  <h3>@dlassad</h3>
                  <p>Todos deveriam conhecer o @kotasoficial</p>

                  <img src="../assets/landing-page/tw_logo.png" class="tw" alt="Ícone X" />
                </div>
              </a>
            </slide>
          </carousel>
        </div>
      </div>
    </section>

    <section class="content reverse wrapper">
      <div class="faq">
        <p style="text-align: center">Perguntas frequentes</p>
        <h2 class="h2">
          Esclarecemos as dúvidas
          <br />mais comuns
        </h2>
        <Accordion width="740" titulo="Como funciona o Kotas?" border>
          <template #conteudo>
            <p>
              Te ajudamos a economizar nas suas assinaturas dividindo os custos em grupos.
              Fornecemos ferramentas financeiras para você organizar suas assinaturas e conectar com
              quem mora com você, familiares e amigos. Saiba mais
              <a href="https://www.kotas.com.br/r/#art-primeiros-passos" rel="noreferrer"
                >clicando aqui</a
              >
            </p>
          </template>
        </Accordion>

        <Accordion width="740" titulo="Quais as formas de pagamento?" border>
          <template #conteudo>
            <p>
              Os pagamentos são feitos para o site Kotas através de
              <strong>boleto, cartão ou transferidos</strong> com seu saldo em sites como Mercado
              Pago, PicPay, etc.
            </p>
          </template>
        </Accordion>

        <Accordion width="740" titulo="O que é um grupo de assinatura?" border>
          <template #conteudo>
            <p>
              Grupos são feitos de duas ou mais pessoas que tem
              <strong>acesso a uma mesma assinatura.</strong>
              <br />
              <br />Se você tem uma assinatura e quer compartilhar o acesso dos espaços não
              utilizados você é conhecido como <b>administrador.</b> Caso você não tenha a
              assinatura e queira fazer parte de um grupo você participa como um
              <b>membro.</b>
            </p>
          </template>
        </Accordion>

        <Accordion
          width="740"
          titulo="O que acontece se alguém do meu grupo esquecer de pagar?"
          border
        >
          <template #conteudo>
            <p>
              <strong>A mensalidade é paga de forma antecipada.</strong> Nossos sistemas de
              acompanhamento e lembretes são bastante eficientes. Mantemos os contatos atualizados
              para comunicação por e-mail, SMS e notificações. Também estamos integrados para
              cobranças automáticas por cartão de crédito minimizado a inadimplência.
              <br />
              <br />Se ainda assim não for suficiente, temos o <strong>valor caução.</strong> Ao
              participar de um grupo é <strong>cobrado antecipadamente</strong> o valor de uma
              mensalidade que fica retida na conta do membro. Em caso de não identificarmos
              pagamento o valor caução é utilizado na mensalidade, o administrador é comunicado e a
              vaga aberta com bastante antecedência.
            </p>
          </template>
        </Accordion>

        <Accordion width="740" titulo="O que acontece se a assinatura parar de funcionar?" border>
          <template #conteudo>
            <p>
              Os repasses ao administrador são realizados após o mês de uso, ficando seguros em caso
              de problemas.
            </p>
          </template>
        </Accordion>

        <Accordion width="740" titulo="Posso cancelar a qualquer momento?" border>
          <template #conteudo>
            <p>
              Sim! Os cancelamentos são sempre agendados baseados no vencimento da sua mensalidade.
              É necessária a antecedência para que o administrador se programe, mas o cancelamento
              pode ser feito a qualquer momento.
            </p>
          </template>
        </Accordion>

        <Accordion
          width="740"
          titulo="Posso dividir qualquer serviço com qualquer pessoa?"
          border
          init-open
        >
          <template #conteudo>
            <p>
              Não é possível generalizar. Cada serviço tem sua própria regra de divisão.
              <strong>
                Alguns exigem que você more no mesmo endereço. Outros que você seja da mesma
                família. Várias assinaturas permitem que compartilhe entre amigos e até
                desconhecidos.
              </strong>
              É preciso conhecer cada limitação do que está compartilhando. O Kotas te auxilia na
              parte financeira da divisão.
            </p>

            <p>
              Um exemplo disso é que o Kotas
              <strong>
                não promove o compartilhamento de assinaturas coletivas de qualquer conteúdo
                oferecido pelo Gran Cursos Online e respectivo Grupo Econômico.
              </strong>
            </p>
          </template>
        </Accordion>

        <a
          v-gtm.click="{
            category: 'home',
            action: 'click',
            label: '6-cta-criar-uma-conta-no-kotas',
          }"
          href="https://app.kotas.com.br/cadastro"
        >
          <button class="btn-primary">Criar uma conta no Kotas</button>
        </a>
      </div>

      <div>
        <img
          src="../assets/landing-page/undraw_questions-large.webp"
          width="424"
          height="331"
          srcset="
            ../assets/landing-page/undraw_questions-small.webp 408w,
            ../assets/landing-page/undraw_questions-large.webp 424w
          "
          class="img-decoration img undrawquestions"
          sizes="(max-width: 480px) 408w, 424w"
          alt
        />
      </div>
    </section>

    <footer class="grey-section">
      <div class="content">
        <div class="footer">
          <div class="social">
            <div>
              <a href="https://www.facebook.com/kotasoficial/" rel="noreferrer">
                <img src="../assets/landing-page/facebook.webp" alt="facebook" />
              </a>
            </div>
            <div>
              <a href="https://x.com/kotasoficial" rel="noreferrer">
                <img src="../assets/landing-page/twitter.png" alt="X" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/kotas_oficial/" rel="noreferrer">
                <img src="../assets/landing-page/instagram.webp" alt="instagram" />
              </a>
            </div>
            <div>
              <a href="https://t.me/kotasoficial" rel="noreferrer">
                <img src="../assets/landing-page/telegram.png" alt="telegram" />
              </a>
            </div>
          </div>
          <div>
            <ul class="footer-ul">
              <li>
                <a href="https://www.kotas.com.br/blog/termos-de-uso/" rel="noreferrer"
                  >termos de uso</a
                >
              </li>
              <li>
                <a href="https://www.kotas.com.br/blog/politica-de-privacidade/" rel="noreferrer"
                  >política de privacidade</a
                >
              </li>
              <li>
                <a href="https://www.kotas.com.br/blog/" rel="noreferrer">blog</a>
              </li>
              <li>
                <a href="https://www.kotas.com.br/r/#art-entrar-contato" rel="noreferrer"
                  >contato</a
                >
              </li>
              <li>
                <a href="https://www.kotas.com.br/r/#art-taxas" rel="noreferrer">taxas</a>
              </li>
            </ul>
          </div>

          <div class="footer-kotas-plus">
            <p>
              Conheça o
              <a href="https://www.kotasplus.com.br/" target="_blank" rel="noreferrer">Kotas Plus</a
              >, o novo produto do Kotas
            </p>
          </div>

          <p>
            Av. Cristiano Machado, 1648 – Salas 801 e 802 - Cidade Nova, Belo Horizonte - MG
            <br />KOTAS SERVIÇOS DE INTERNET LTDA, CNPJ 27.656.455/0001-16
          </p>
        </div>
        <div id="janela-cookies" class="container-inicial"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import LogoKotas from '../assets/landing-page/kotas_logo.svg';
import NavBar from '@/components/NavBar';
import UndrawPizza from '../assets/landing-page/undraw_pizza.svg';
import Cinema from '../assets/landing-page/cinema.svg';
import Curso from '../assets/landing-page/curso.svg';
import Musica from '../assets/landing-page/musica.svg';
import Game from '../assets/landing-page/game.svg';
import Union from '../assets/landing-page/Union.svg';
import Coin from '../assets/landing-page/Coin.svg';
const Accordion = () => import('../components/Accordion.vue');

export default {
  name: 'landing',

  head: {
    meta: [
      {
        name: 'description',

        content:
          'Divida os principais serviços de assinatura de streamings, músicas, cursos, leitura, escritório e jogos e economize até 80% no valor final.',
      },
    ],

    link: [
      {
        rel: 'canonical',
        href: 'https://www.kotas.com.br/',
      },
    ],
  },

  data() {
    return {
      words: ['Streamings', 'Música', 'Cursos', 'jogos'],
    };
  },
  components: {
    NavBar,
    Accordion,
    LogoKotas,
    UndrawPizza,
    Game,
    Union,
    Coin,
    Cinema,
    Curso,
    Musica,
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap');

.landing {
  margin: 0;
  background-color: white;
}

img.logo {
  width: 80px;
  height: 24px;
}

.landing h2 {
  font-size: 20px;
  font-family: 'KotasFont';
  color: #000000;
  font-weight: 400;
  margin: 4px 0;
}

.landing p {
  font-size: 20px;
  font-family: 'KotasFont';
  text-align: left;
  margin-bottom: 16px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    text-align: center;
    font-size: 16px;
    line-height: 140%;
  }
}

.landing h1 {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  text-align: left;
  color: #30455c;
  font-family: 'KotasFont';
  margin-bottom: 16px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    text-align: center;
    font-size: 26px;
    line-height: 124%;
  }
}

h1.landing-title {
  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    font-size: 36px;
    line-height: 124%;
  }
}

.landing .img-decoration {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    height: auto;
    max-width: 85vw;
    margin: 32px 0;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    height: auto;
    max-width: 85vw;
    margin: 32px 0;
    padding: 0 16px;
  }
}

.allpersons {
  width: 784px;
  height: 357px;
}

.undrawpizza {
  width: 566rem;
}

.undrawmissedchances {
  width: 475px;
  height: 384px;
}

.undrawjudge {
  width: 360px;
  height: 325px;
}

.midias {
  width: 650px;
}

.undrawquestions {
  width: 424px;
  height: 331px;
  max-width: none;

  @media only screen and (min-device-width: 1024px) {
    margin-left: 32px;
  }
}

.btn-primary {
  width: auto;
  min-width: 260px;
  padding: 6px 12px;
  margin: 32px 0;
  border-radius: 24px;
  font-weight: 740;

  &:hover {
    transition: all 0.5s;
    background-color: #0d66d0;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    margin: 32px auto;
  }
}

.btn-secondary {
  width: auto;
  min-width: 260px;
  padding: 6px 12px;
  margin: 32px 0;
  border-radius: 24px;
  font-weight: 740;
  background-color: #0cd4ad;

  &:hover {
    transition: all 0.5s;
    background-color: #2f9996;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    margin: 32px auto;
  }
}

.btn-primary a,
.btn-secondary a {
  color: white;
}

.textra {
  font-weight: 800;
  font-size: 40px;
  text-align: left;
  color: #01b1ec;
  font-family: 'KotasFont';
  margin-bottom: 32px;
  margin-top: -12px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    font-size: 36px;
    text-align: center;
    min-height: 80px;
  }
}

.grey-section {
  display: flex;
  justify-content: center;
  background-color: #fafafb;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 124px 64px;
  max-width: 1324px !important;
  margin: auto;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    text-align: center;
    justify-content: center;
    max-width: 480px;
    width: auto;
    margin: 32px 0;
    padding: 32px 16px;
    flex-wrap: wrap;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    text-align: center;
    justify-content: center;
    max-width: 100%;
    width: auto;
    margin: 32px 16px;
    padding: 32px 16px;
    flex-wrap: wrap;
  }
}

.content.reverse {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    padding: 32px 0;
    flex-wrap: wrap-reverse;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    padding: 32px 16px;
    flex-wrap: wrap-reverse;
  }
}

.img-fluid {
  width: 116px;
  height: 116px;
  filter: grayscale(1);

  &:hover {
    transition: 1s;
    filter: grayscale(0);
  }
}

.carousel {
  width: 80%;
  margin: 16px 0;

  & h1 {
    margin: 32px 0;

    @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
      font-size: 26px;
      line-height: 120%;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    margin: auto;
  }
}

.VueCarousel {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: flex;
    align-content: center;
    align-items: center;
    max-width: 384px;
    padding: 0 0px;
    margin: auto;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 16px;
    margin: auto;
  }
}

.VueCarousel-pagination {
  text-align: left !important;

  & button[aria-selected='true'] {
    background-color: #01b1ec !important;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    text-align: center !important;
  }
}

.tw-section .VueCarousel-pagination {
  display: flex;
  text-align: center;

  & button[aria-selected='true'] {
    background-color: #01b1ec !important;
  }
}

.x-carousel {
  padding-left: 64px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    width: 95vw;
    padding-left: 0;
  }

  & a {
    margin: 0;
    padding: 0;
    width: auto;
  }
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  width: 100%;
}

.tweet {
  position: relative;
  max-width: 380px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  color: #141728;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  padding: 16px;
  margin: 0 auto;

  & h2 {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin: 4px 0;
  }

  & h3 {
    font-size: 14px;
    font-weight: bold;
    color: #a2a2a2;
    text-align: center;
    margin: 4px 0;
  }

  & p {
    text-align: center;
    margin-top: 16px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    margin: auto;
    max-width: 300px;
  }
}

.tweet .tw {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
}

.cards-box {
  margin: 0 32px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    margin: 16px auto 64px auto;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.top1 {
  max-width: 150px;
  width: 100%;
  height: 100px;
  background-image: url(../assets/landing-page/pessoas.svg);
  margin-bottom: 25px;
  float: left;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-right: 8px;
  }
}

.top2 {
  max-width: 150px;
  width: 100%;
  height: 100px;
  background-image: url(../assets/landing-page/pessoas.svg);
  margin-bottom: 25px;
  background-position-x: -170px;
  float: left;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-right: 0px;
  }
}

.top3 {
  max-width: 150px;
  width: 100%;
  height: 100px;
  background-image: url(../assets/landing-page/pessoas.svg);
  margin-bottom: 25px;
  background-position-x: -334px;
  float: left;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-top: 16px;
    margin-right: 8px;
  }
}

.top4 {
  max-width: 150px;
  width: 100%;
  height: 100px;
  background-image: url(../assets/landing-page/pessoas.svg);
  margin-bottom: 25px;
  background-position-x: -504px;
  float: left;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-top: 16px;
    margin-right: 0px;
  }
}

.card-img {
  width: 150px;
  float: left;
  margin-right: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px #e1e1e1;
  border-radius: 8px;
  border: none;

  @media (max-width: 767px) {
    margin-right: 8px;
  }
}

.card-img .card-body {
  padding: 8px;
}

.card-img .icone {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #eaf4ff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f5f5f5;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 6px;
}

.card-img .icone svg {
  width: 21px;
  height: 21px;
  display: block;
  padding: 0;
  margin: 5px;
}

.card-img .card-title {
  font-family: 'KotasFont';
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}

.card-img .card-text {
  display: flex;
  margin: 8px 0;
  font-size: 10px;
  font-weight: bold;
  align-items: center;

  & img,
  b {
    margin: 0 4px 0 0;
    padding: 0;
  }
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;

  & h1 {
    margin: 32px 0 8px 0;
    font-size: 10px;
    line-height: 100%;
  }

  & p {
    font-size: 8px;
    color: #616161;
    line-height: 100%;
  }

  & .foto1 {
    margin: 16px 8px 0 0;
    width: 32px;
    height: 32px;
    background-image: url(../assets/landing-page/pessoa_1.webp);
    background-size: cover;
    background-repeat: no-repeat;
  }

  & .foto2 {
    margin: 16px 8px 0 0;
    width: 32px;
    height: 32px;
    background-image: url(../assets/landing-page/pessoa_2.webp);
    background-size: cover;
    background-repeat: no-repeat;
  }

  & .foto3 {
    margin: 16px 8px 0 0;
    width: 32px;
    height: 32px;
    background-image: url(../assets/landing-page/pessoa_3.webp);
    background-size: cover;
    background-repeat: no-repeat;
  }

  & .foto4 {
    margin: 16px 8px 0 0;
    width: 32px;
    height: 32px;
    background-image: url(../assets/landing-page/pessoa_4.webp);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.faq h2 {
  font-family: 'KotasFont';
  font-size: 16px;
  font-weight: bold;
  color: #616161;
}

.faq p {
  font-family: 'KotasFont';
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.faq b {
  font-weight: bold;
}

strong {
  font-weight: bold !important;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & ul li {
    font-family: 'KotasFont';
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    margin: 32px 16px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }

  & p {
    color: #616161;
    text-align: center;
    font-size: 14px;
  }
}

.footer .social {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & img {
    width: 32px;
    height: 32px;
    margin: 0 16px;
  }
}

.footer-ul {
  padding: 0;
}

.footer-ul {
  padding: 0;
}

.wrapper {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

body section .h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  text-align: left;
  color: #30455c;
  font-family: 'KotasFont';
  margin-bottom: 16px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    text-align: center;
    font-size: 26px;
    line-height: 124%;
  }
}

body section .h3 {
  font-size: 20px;
  font-family: 'KotasFont';
  color: #000000;
  font-weight: 400;
  margin: 4px 0;
}

.footer-kotas-plus {
  & p {
    font-family: 'Mulish';
    font-weight: bold;
    font-size: 1.1rem;
    color: #595959;

    & a {
      color: #01b1ec;
      text-decoration: underline;
    }
  }
}
</style>
