import Vue from 'vue';
import App from './App.vue';
import directives from './directives/index';
import router from './router';
import './plugins';

Vue.config.productionTip = false;
Vue.directive('gtm', directives.gtm);

/*
 * Gambis necessária para remover a meta-tag do fb:idSOMENTE
 * para usuários e deixar para o scraper do FB.
 */
document.querySelector('[content="155006601567139"]').remove();

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
