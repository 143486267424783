export function setCookie({ name, value, expires, path, domain, secure }) {
  let cookie = name + '=' + value;

  if (expires) {
    if (expires instanceof Date) {
      cookie += '; expires=' + expires.toUTCString();
    } else {
      cookie += '; expires=' + new Date(expires).toUTCString();
    }
  }

  if (path) {
    cookie += '; path=' + path;
  }

  if (domain) {
    cookie += '; domain=' + domain;
  }

  if (secure) {
    cookie += '; secure';
  }

  document.cookie = cookie;
}

export function getCookies() {
  let cookies = document.cookie.split(';');

  if (!cookies[0]) {
    return null;
  }

  let result = {};
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    let index = cookie.indexOf('=');
    let name = cookie.substring(0, index);
    let value = cookie.substring(index + 1);
    result[name] = decodeURIComponent(value);
  }
  return result;
}

export function getCookie(name) {
  let nameEQ = name + '=';
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) == 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
}

export function deleteCookie(name, domain) {
  document.cookie = name +'=; Path=/;  Domain=' + domain +  '; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure'
}
