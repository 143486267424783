import Vue from 'vue';
import VueGtm from 'vue-gtm';

Vue.use(VueGtm, {
  id: 'GTM-KZVF6M',
  defer: false,
  enabled: false,
  debug: false,
  loadScript: true,
  trackOnNextTick: false,
});

/**
 *
 * @description
 *  Precisamos fazer um wrapper para a função trackView() do VueGtm, pois
 *  ela é invocada automaticamente quando o VueGtm é iniciado, e isso
 *  faz com que o evento 'content-view' seja enviado para o dataLayer 2 vezes.
 */
Vue.prototype.$gtm.trackView = (contentName, contentViewName, enabled) => {
  if (enabled) {
    dataLayer.push({
      event: 'content-view',
      'content-name': contentName,
      'content-view-name': contentViewName,
    });
  }
};
