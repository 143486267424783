var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"nav",staticClass:"nav",class:{ 'nav--fixed': _vm.isScrollUp },attrs:{"dark":"false"}},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"},on:{"after-enter":_vm.onAfterEnter,"after-leave":_vm.onAfterLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobileOpen),expression:"isMobileOpen"}],staticClass:"nav__mobile__aside"},[_c('div',{staticClass:"nav__mobile__aside__overlay",on:{"click":function($event){$event.stopPropagation();_vm.isMobileOpen = false}}}),_c('Transition',{attrs:{"name":"fade-left","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOverlayOpen),expression:"isOverlayOpen"}],staticClass:"nav__mobile__aside__body"},[_c('div',[_c('div',{staticClass:"nav__mobile__aside__body__header"},[_c('RouterLink',{staticClass:"nav__mobile__aside__body__header__logo",attrs:{"to":"/"}},[_c('LogoKotas',{attrs:{"id":"black","width":"120","height":"36","aria-label":"Logo do Kotas"}}),_c('LogoKotasWhite',{attrs:{"id":"white","width":"120","height":"36","aria-label":"Logo do Kotas"}})],1),_c('div',{staticClass:"nav__mobile__aside__body__close",on:{"click":function($event){_vm.isMobileOpen = false}}},[_c('X',{attrs:{"height":"26","width":"26"}})],1)],1)]),_c('div',{staticClass:"nav__mobile__aside__body__links"},[_c('ul',[_c('li',[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
                    category: _vm.target,
                    action: 'click',
                    label: 'menu-primeiros-passos',
                  }),expression:"{\n                    category: target,\n                    action: 'click',\n                    label: 'menu-primeiros-passos',\n                  }",modifiers:{"click":true}}],attrs:{"href":"https://www.kotas.com.br/r/#art-primeiros-passos"}},[_c('span',[_vm._v("Primeiros Passos")]),_c('p',[_vm._v("Comece por aqui")])])]),_c('li',[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
                    category: _vm.target,
                    action: 'click',
                    label: 'menu-grupos',
                  }),expression:"{\n                    category: target,\n                    action: 'click',\n                    label: 'menu-grupos',\n                  }",modifiers:{"click":true}}],attrs:{"href":"https://app.kotas.com.br/pesquisa"}},[_c('span',[_vm._v("Grupos")]),_c('p',[_vm._v("O que estão compartilhando?")])])]),_c('li',[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
                    category: _vm.target,
                    action: 'click',
                    label: 'menu-ajuda',
                  }),expression:"{\n                    category: target,\n                    action: 'click',\n                    label: 'menu-ajuda',\n                  }",modifiers:{"click":true}}],attrs:{"href":"https://www.kotas.com.br/r/#ajuda"}},[_c('span',[_vm._v("Ajuda")]),_c('p',[_vm._v("Em que posso te ajudar?")])])])]),_c('div',{staticClass:"nav__app"},[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
                  category: _vm.target,
                  action: 'click',
                  label: 'menu-entrar',
                }),expression:"{\n                  category: target,\n                  action: 'click',\n                  label: 'menu-entrar',\n                }",modifiers:{"click":true}}],staticClass:"nav__app__sign-in",attrs:{"href":"https://app.kotas.com.br/login"}},[_c('span',[_vm._v("Entrar")])]),_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
                  category: _vm.target,
                  action: 'click',
                  label: 'menu-cadastre-se',
                }),expression:"{\n                  category: target,\n                  action: 'click',\n                  label: 'menu-cadastre-se',\n                }",modifiers:{"click":true}}],staticClass:"nav__app__sign-up",attrs:{"href":"https://app.kotas.com.br/cadastro"}},[_c('span',[_vm._v("Cadastre-se ")])])])])])])],1)]),_c('div',{staticClass:"nav__mobile"},[_c('RouterLink',{staticClass:"nav__mobile__logo",attrs:{"to":"/"}},[_c('LogoKotas',{attrs:{"id":"black","height":"36","width":"120","aria-label":"Logo do Kotas"}}),_c('LogoKotasWhite',{attrs:{"id":"white","width":"120","height":"36","aria-label":"Logo do Kotas"}})],1),_c('div',{staticClass:"nav__mobile__menu",on:{"click":function($event){_vm.isMobileOpen = !_vm.isMobileOpen}}},[_c('Hamburguer',{attrs:{"height":"30","width":"30"}})],1)],1),_c('div',{staticClass:"nav__desktop"},[_c('div',{staticClass:"nav__desktop__right"},[_c('RouterLink',{staticClass:"nav__desktop__right__logo",attrs:{"to":"/"}},[_c('LogoKotas',{attrs:{"id":"black","height":"45","width":"140","aria-label":"Logo do Kotas"}}),_c('LogoKotasWhite',{attrs:{"id":"white","width":"120","height":"36","aria-label":"Logo do Kotas"}})],1),_c('ul',{staticClass:"nav__links"},[_c('li',[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
              category: _vm.target,
              action: 'click',
              label: 'menu-primeiros-passos',
            }),expression:"{\n              category: target,\n              action: 'click',\n              label: 'menu-primeiros-passos',\n            }",modifiers:{"click":true}}],attrs:{"href":"https://www.kotas.com.br/r/#art-primeiros-passos"}},[_c('span',[_vm._v("Primeiros Passos")]),_c('p',[_vm._v("Comece por aqui")])])]),_c('li',[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
              category: _vm.target,
              action: 'click',
              label: 'menu-grupos',
            }),expression:"{\n              category: target,\n              action: 'click',\n              label: 'menu-grupos',\n            }",modifiers:{"click":true}}],attrs:{"href":"https://app.kotas.com.br/pesquisa"}},[_c('span',[_vm._v("Grupos")]),_c('p',[_vm._v("O que estão compartilhando?")])])]),_c('li',[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
              category: _vm.target,
              action: 'click',
              label: 'menu-ajuda',
            }),expression:"{\n              category: target,\n              action: 'click',\n              label: 'menu-ajuda',\n            }",modifiers:{"click":true}}],attrs:{"href":"https://www.kotas.com.br/r/#ajuda"}},[_c('span',[_vm._v("Ajuda")]),_c('p',[_vm._v("Em que posso te ajudar?")])])])])],1),_c('div',{staticClass:"nav__app"},[_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
          category: _vm.target,
          action: 'click',
          label: 'menu-entrar',
        }),expression:"{\n          category: target,\n          action: 'click',\n          label: 'menu-entrar',\n        }",modifiers:{"click":true}}],staticClass:"nav__app__sign-in",attrs:{"href":"https://app.kotas.com.br/login"}},[_c('span',[_vm._v("Entrar")])]),_c('a',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
          category: _vm.target,
          action: 'click',
          label: 'menu-cadastre-se',
        }),expression:"{\n          category: target,\n          action: 'click',\n          label: 'menu-cadastre-se',\n        }",modifiers:{"click":true}}],staticClass:"nav__app__sign-up",attrs:{"href":"https://app.kotas.com.br/cadastro"}},[_c('span',[_vm._v("Cadastre-se ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }