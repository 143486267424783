<script>
import LogoKotas from '@/assets/landing-page/kotas_logo.svg';
import LogoKotasWhite from '@/assets/landing-page/kotas_logo_white.svg';
import Hamburguer from '@/assets/landing-page/svg/hamburguer.svg';
import X from '@/assets/landing-page/svg/x.svg';

export default {
  components: {
    LogoKotas,
    LogoKotasWhite,
    Hamburguer,
    X,
  },

  props: {
    target: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isActive: false,
    isScrollUp: false,
    isMobileOpen: false,
    isOverlayOpen: false,
    nav: null,
  }),

  watch: {
    isMobileOpen(isMobileOpen) {
      document.body.style.overflow = isMobileOpen ? 'hidden' : null;
    },
  },

  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1050) {
        this.isMobileOpen = false;
      }
    });

    let previousScrollPosition = window.pageYOffset;

    window.addEventListener('scroll', () => {
      const currentScrollPosition = window.pageYOffset;
      this.isScrollUp = currentScrollPosition < previousScrollPosition && !this.isMobileOpen;

      if (currentScrollPosition <= 0) {
        this.isScrollUp = false;
      }

      previousScrollPosition = currentScrollPosition;
    });
  },

  methods: {
    onAfterEnter() {
      this.isOverlayOpen = true;
    },
    onAfterLeave() {
      this.isOverlayOpen = false;
    },
  },
};
</script>
<template>
  <nav ref="nav" class="nav" dark="false" :class="{ 'nav--fixed': isScrollUp }">
    <Transition name="fade" mode="out-in" @after-enter="onAfterEnter" @after-leave="onAfterLeave">
      <div v-show="isMobileOpen" class="nav__mobile__aside">
        <div class="nav__mobile__aside__overlay" @click.stop="isMobileOpen = false"></div>

        <Transition name="fade-left" mode="out-in">
          <div v-show="isOverlayOpen" class="nav__mobile__aside__body">
            <div>
              <div class="nav__mobile__aside__body__header">
                <RouterLink to="/" class="nav__mobile__aside__body__header__logo">
                  <LogoKotas id="black" width="120" height="36" aria-label="Logo do Kotas" />
                  <LogoKotasWhite id="white" width="120" height="36" aria-label="Logo do Kotas" />
                </RouterLink>
                <div class="nav__mobile__aside__body__close" @click="isMobileOpen = false">
                  <X height="26" width="26" />
                </div>
              </div>
            </div>
            <div class="nav__mobile__aside__body__links">
              <ul>
                <li>
                  <a
                    v-gtm.click="{
                      category: target,
                      action: 'click',
                      label: 'menu-primeiros-passos',
                    }"
                    href="https://www.kotas.com.br/r/#art-primeiros-passos"
                  >
                    <span>Primeiros Passos</span>
                    <p>Comece por aqui</p>
                  </a>
                </li>
                <li>
                  <a
                    v-gtm.click="{
                      category: target,
                      action: 'click',
                      label: 'menu-grupos',
                    }"
                    href="https://app.kotas.com.br/pesquisa"
                  >
                    <span>Grupos</span>
                    <p>O que estão compartilhando?</p>
                  </a>
                </li>
                <li>
                  <a
                    v-gtm.click="{
                      category: target,
                      action: 'click',
                      label: 'menu-ajuda',
                    }"
                    href="https://www.kotas.com.br/r/#ajuda"
                  >
                    <span>Ajuda</span>
                    <p>Em que posso te ajudar?</p>
                  </a>
                </li>
              </ul>

              <div class="nav__app">
                <a
                  class="nav__app__sign-in"
                  v-gtm.click="{
                    category: target,
                    action: 'click',
                    label: 'menu-entrar',
                  }"
                  href="https://app.kotas.com.br/login"
                >
                  <span>Entrar</span>
                </a>

                <a
                  class="nav__app__sign-up"
                  v-gtm.click="{
                    category: target,
                    action: 'click',
                    label: 'menu-cadastre-se',
                  }"
                  href="https://app.kotas.com.br/cadastro"
                >
                  <span>Cadastre-se </span>
                </a>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>

    <div class="nav__mobile">
      <RouterLink to="/" class="nav__mobile__logo">
        <LogoKotas id="black" height="36" width="120" aria-label="Logo do Kotas" />
        <LogoKotasWhite id="white" width="120" height="36" aria-label="Logo do Kotas" />
      </RouterLink>

      <div class="nav__mobile__menu" @click="isMobileOpen = !isMobileOpen">
        <Hamburguer height="30" width="30" />
      </div>
    </div>

    <div class="nav__desktop">
      <div class="nav__desktop__right">
        <RouterLink to="/" class="nav__desktop__right__logo">
          <LogoKotas id="black" height="45" width="140" aria-label="Logo do Kotas" />
          <LogoKotasWhite id="white" width="120" height="36" aria-label="Logo do Kotas" />
        </RouterLink>

        <ul class="nav__links">
          <li>
            <a
              v-gtm.click="{
                category: target,
                action: 'click',
                label: 'menu-primeiros-passos',
              }"
              href="https://www.kotas.com.br/r/#art-primeiros-passos"
            >
              <span>Primeiros Passos</span>
              <p>Comece por aqui</p>
            </a>
          </li>
          <li>
            <a
              v-gtm.click="{
                category: target,
                action: 'click',
                label: 'menu-grupos',
              }"
              href="https://app.kotas.com.br/pesquisa"
            >
              <span>Grupos</span>
              <p>O que estão compartilhando?</p>
            </a>
          </li>
          <li>
            <a
              v-gtm.click="{
                category: target,
                action: 'click',
                label: 'menu-ajuda',
              }"
              href="https://www.kotas.com.br/r/#ajuda"
            >
              <span>Ajuda</span>
              <p>Em que posso te ajudar?</p>
            </a>
          </li>
        </ul>
      </div>

      <div class="nav__app">
        <a
          class="nav__app__sign-in"
          v-gtm.click="{
            category: target,
            action: 'click',
            label: 'menu-entrar',
          }"
          href="https://app.kotas.com.br/login"
        >
          <span>Entrar</span>
        </a>

        <a
          class="nav__app__sign-up"
          v-gtm.click="{
            category: target,
            action: 'click',
            label: 'menu-cadastre-se',
          }"
          href="https://app.kotas.com.br/cadastro"
        >
          <span>Cadastre-se </span>
        </a>
      </div>
    </div>
  </nav>
</template>
<style scoped lang="scss">
@import './NavBar.scss';
</style>
