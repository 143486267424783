function encurtador(link) {
  const objetoUrl = {
    ajuda: "https://kotas.movidesk.com/kb/",
    "art-aprovacao-grupo":
      "https://kotas.movidesk.com/kb/article/253243/criterios-de-aprovacao-de-grupos-publicos-vs-grupos-privados",
    "art-comp-minha-rede":
      "https://kotas.movidesk.com/kb/article/253277/como-funcionam-os-grupos-compartilhados-com-minha-rede-de-amigos",
    "art-membros-grup-priv":
      "https://kotas.movidesk.com/kb/article/253290/como-convidar-membros-para-meu-grupo-privado",
    "art-indicar-amigo":
      "https://kotas.movidesk.com/kb/article/253256/como-indicar-um-amigo",
    "art-primeiros-passos":
      "https://kotas.movidesk.com/kb/article/250751/primeiros-passos",
    "art-primeiros-passos-membro":
      "https://kotas.movidesk.com/kb/article/253188/primeiros-passos-como-membro",
    "art-primeiros-passos-adm":
      "https://kotas.movidesk.com/kb/article/252939/primeiros-passos-como-administrador",
    "art-entrar-contato":
      "https://kotas.movidesk.com/kb/article/253192/como-entrar-em-contato-com-o-kotas",
    "art-taxas": "https://kotas.movidesk.com/kb/article/253763/taxas",
    "art-credito-caucao":
      "https://kotas.movidesk.com/kb/article/253749/o-que-e-um-credito-caucao",
    "art-com-quem-posso-compartilhar":
      "https://kotas.movidesk.com/kb/article/253225/com-quem-posso-compartilhar-minha-assinatura",
    "art-quando-terei-acesso-ao-servico":
      "https://kotas.movidesk.com/kb/article/253245/quando-terei-acesso-ao-servico",
    "art-quais-formas-pagamento":
      "https://kotas.movidesk.com/kb/article/253760/quais-as-formas-de-pagamento-aceitas",
    "art-grupos-privados-vs-publicos":
      "https://kotas.movidesk.com/kb/article/253287/como-grupos-privados-se-diferem-dos-publicos",
    "art-clonar-um-grupo":
      "https://kotas.movidesk.com/kb/article/253279/clonar-um-grupo",
    "art-ativar-2fa":
      "https://kotas.movidesk.com/kb/article/253857/como-ativar-a-autenticacao-em-dois-fatores-2fa",
    "art-tive-problema":
      "https://kotas.movidesk.com/kb/article/253282/tive-um-problema-no-meu-grupo-como-faco-para-resolver",
    "art-selos":
      "https://kotas.movidesk.com/kb/article/253489/o-que-sao-selos-de-grupos-e-administrador",
  };
  if (objetoUrl[link]) window.location.href = objetoUrl[link];
  else return;
}

export { encurtador };
