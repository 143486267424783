import Vue from 'vue';
import { resolveDomain, setCookie, getExpirationTime } from '@/plugins/@kotas-analytics';
import { sleep } from '@/shared/sleep';

const domain = (window._cookieconsentDomain = resolveDomain());

const configs = {
  current_lang: 'pt',
  autorun: true,
  theme_css: 'cookieconsent.css',
  force_consent: true,
  cookie_domain: domain,
  async onAccept({ level }) {
    try {
      await sleep(250);
      Vue.prototype.$gtm.enable(true);
    } catch (error) {
      console.log('Cookie consent: Não foi possível ativar o GTM', error);
    }

    setCookie({
      domain,
      name: 'lgpd',
      expires: getExpirationTime(),
      value: `cookies: ${JSON.stringify(level.map((cookie) => cookie))}`,
    });
  },
  gui_options: {
    consent_modal: {
      layout: 'bar', // box/cloud/bar
      position: 'bottom center', // bottom/top + left/right/center
      transition: 'slide', // zoom/slide
    },
    settings_modal: {
      layout: 'bar', // box/bar
      position: 'left', // left/right
      transition: 'slide', // zoom/slide
    },
  },
  languages: {
    pt: {
      consent_modal: {
        title: 'Nós gostamos de cookies de chocolate ;)',
        description:
          'Nós usamos cookies para proporcionar uma melhor e mais segura experiência para você.',
        primary_btn: {
          text: 'Aceitar',
          role: 'accept_selected', //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Opções',
          role: 'settings', //'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Configuração de Cookies',
        save_settings_btn: 'Salvar configurações',
        accept_all_btn: 'Aceitar todos',
        close_btn_label: 'Fechar',
        blocks: [
          {
            title: 'Cookies usados',
            description: 'Alguns cookies são obrigatórios e não podem ser desabilitados.',
          },
          {
            title: 'Necessários',
            description:
              'são essenciais para o funcionamento da plataforma, sem eles a plataforma não funcionaria adequadamente. (Ex. acesso a áreas seguras do site, antifraude, legislação, etc)',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            },
          },
          {
            title: 'Estatísticas',
            description:
              'traduzem as interações dos visitantes em relatórios detalhados de comportamento. A informação fornecida pelos cookies analíticos nos permite analisar padrões do comportamento do visitante, e essas informações são utilizadas para melhorar a experiência em geral ou identificar áreas do website que necessitam de manutenção. Esta informação não contêm informação pessoal como nome e endereço de e-mail.',
            toggle: {
              value: 'analytics_storage',
              enabled: true,
              readonly: false,
            },
          },
          {
            title: 'Marketing',
            description:
              'possuem a finalidade de identificar o interesse e comportamento do usuário, rastreando e coletando informações sobre as atividades dos usuários, permitindo assim a identificação dos seus padrões de acesso, navegação, interesse e compras, iremos utilizados estes dados para otimizar o direcionamento de conteúdo.',
            toggle: {
              value: 'ad_storage',
              enabled: true,
              readonly: false,
            },
          },
        ],
      },
    },
  },
};

export { configs };
