import Vue from 'vue';

function getListener(modifiers) {
  return Object.keys(modifiers)?.[0];
}

function bind(el, bidding) {
  const listener = getListener(bidding.modifiers);
  const event = bidding.value;

  if (!('_gtm' in el)) {
    el._gtm = {};
  }

  el.addEventListener(listener, trackEvent);

  el._gtm[listener] = unTrackEvent;

  function trackEvent() {
    Vue.prototype.$gtm.trackEvent(event);
  }

  function unTrackEvent() {
    el.removeEventListener(listener, trackEvent);
    delete el._gtm[listener];
  }
}

function unbind(el, binding) {
  const unTrackEvent = el._gtm?.[getListener(binding.modifiers)];
  if (unTrackEvent instanceof Function) {
    unTrackEvent();
  }
}

export default {
  bind,
  unbind,
};
