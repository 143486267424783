<template>
  <RouterView />
</template>

<script>
import './styles/main.scss';
import { encurtador } from './services/encurtador';
import { setUTMCookie, getUTMQuery } from '@/plugins/@kotas-analytics';
import { configs } from './services/cookie-consent/config';
import kotasConsent from '@kotasdev/kotas-consent';

export default {
  name: 'App',

  mounted() {
    try {
      setUTMCookie(getUTMQuery());
      encurtador(window.location.pathname.split('/')[2]);

      this.verificarCookieExiste();

      const cookieconsent = kotasConsent();
      cookieconsent.run(configs);
    } catch {
      return;
    }
  },

  methods: {
    verificarCookieExiste() {
      const cookieControle = document.cookie
        .split(';')
        .filter((cookie) => cookie.includes('cc_cookie='));
      if (cookieControle.length > 0) {
        if (
          !cookieControle[0]?.includes('necessary') ||
          !cookieControle[0]?.includes('ad_storage') ||
          !cookieControle[0]?.includes('analytics_storage')
        ) {
          this.deletarCookie('cc_cookie');

          return true;
        } else {
          return false;
        }
      }
    },
    deletarCookie(cookie) {
      const domain = window.location.hostname === 'localhost' ? 'localhost' : '.kotas.com.br';
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domain};path=/;`;
    },
  },
};
</script>
