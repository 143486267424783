import Home from '@/pages/Home.vue';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/servico/:path',
    name: 'Serviço Genérico',
    component: () => import(/* webpackChunkName: "Generic" */ '@/Generic/index.vue'),
  },
  {
    path: '/campanha',
    name: 'Campanha',
    component: () => import(/* webpackChunkName: "Campanha" */ '@/pages/Campanha'),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/pages/404.vue'),
  },
];
