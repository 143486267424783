/**
 * @description get search query string from url and convert to object key value pair
 * @returns {object}
 */
export function queryStringToObject() {
  const queryString = window.location.search;
  const queryStringWithoutQuestionMark = queryString.length > 0 ? queryString.slice(1) : '';
  const keyValuePairs = queryStringWithoutQuestionMark.split('&');

  if (!keyValuePairs[0]) {
    return null;
  }

  const result = {};

  for (let i = 0; i < keyValuePairs.length; i++) {
    const keyValuePair = keyValuePairs[i].split('=');
    const key = keyValuePair[0];
    const value = keyValuePair.length > 1 ? keyValuePair[1] : null;
    result[key] = value;
  }

  return result;
}
